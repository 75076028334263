import { createTheme } from '@mui/material/styles';

export const customTheme = createTheme({
    typography: {
        fontFamily: "'Cairo', sans-serif",
        h1: {fontFamily: "'Cairo', sans-serif"},
        h2: {fontFamily: "'Cairo', sans-serif"},
        h3: {fontFamily: "'Cairo', sans-serif"},
        h4: {fontFamily: "'Cairo', sans-serif"},
        h5: {fontFamily: "'Cairo', sans-serif"},
        h6: {fontFamily: "'Cairo', sans-serif"},
        subtitle1: {fontFamily: "'Cairo', sans-serif"},
        subtitle2: {fontFamily: "'Cairo', sans-serif"},
        body1: {fontFamily: "'Cairo', sans-serif"},
        body2: {fontFamily: "'Cairo', sans-serif"},
        button: {fontFamily: "'Cairo', sans-serif"},
        caption: {fontFamily: "'Cairo', sans-serif"},
        overline: {fontFamily: "'Cairo', sans-serif"},
    },
    palette:{
        action:{
            hover: 'rgba(0, 0, 0, 0.1)',
            hoverOpacity: 0.1
        }
    }
});
// import logo from './logo.svg';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { NotFoundPage } from './Pages/NotFoundPage'
import { ResetPasswordPage } from './Pages/ResetPasswordPage';
import { ServerErrorPage } from './Pages/ServerErrorPage';
import { PrivacyPage } from './Pages/PrivacyPage';

function App() {
  return (
    <div className="App">

      <Routes>
          <Route path="/" element={<NotFoundPage />} />
          <Route path="/reset-password/:_id/:token" element={<ResetPasswordPage />} />

          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/serverError" element={<ServerErrorPage />} />
          <Route path="*" element={<NotFoundPage />} />
      </Routes>

    </div>
  );
}

export default App;

import Joi from 'joi';

export const ResetPasswordSchema = Joi.object({
    password: Joi.string().trim().min(8)
    .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_+!@#$%^&*()\\[\\]{};:"\'<>,.?/|`~])[a-zA-Z0-9-_+!@#$%^&*()\\[\\]{};:"\'<>,.?/|`~]+$')).required()
    .messages({
        'string.pattern.base': 'كلمة المرور يجب أن تحتوي علي الأقل على حرف كبير، وحرف صغير، ورقم، وحرف خاص',
        'string.min': 'كلمة المرور يجب أن تحتوي على الأقل 8 أحرف',
        'any.required': 'كلمة المرور مطلوبة',
        'string.empty': 'كلمة المرور لا يمكن أن يكون فارغة'
    }),
    re_password: Joi.string().trim().valid(Joi.ref('password')).required().messages({
      'any.only': 'تأكيد كلمة المرور يجب أن تكون متطابقة',
      'any.required': 'تأكيد كلمة المرور مطلوب',
      'string.empty': 'تأكيد كلمة المرور لا يمكن أن يكون فارغًا',
    }),
});
import { Typography } from '@mui/material';
import { useState, useEffect } from 'react'

export const usePageTitle = (defaultTitle = '') => {
    const [title] = useState(defaultTitle);

    useEffect(() => {
        window.document.title = `${process.env.REACT_APP_NAME} | ${title}`
    }, [title]);

  return title && <Typography variant="h5" component="div" sx={{ mb: 2.5 }}> { title } </Typography>
}

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';

export const AppAlert = ({ open, type, msg }) => {

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} className="appAlert"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>

        <Alert variant="filled" severity={type} sx={{ width: '100%' }}>
          { msg }
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Alert>
        
      </Snackbar>
    </div>
  );
}
import { useState, useEffect } from 'react'
import { usePageTitle } from '../hooks/usePageTitle'
import { PublicApi } from "../api/api"
import { Box, Stack, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, useParams } from 'react-router-dom';
import { ResetPasswordSchema } from '../validation/AdminAuth.validation';
import EditIcon from '@mui/icons-material/Edit';
import { AppAlert } from '../components/AppAlert';
import CloseIcon from '@mui/icons-material/Close';

export const ResetPasswordPage = () => {

  const title = usePageTitle('إسترجاع كلمة المرور')

  const navigate = useNavigate()

  const { _id, token } = useParams();

  const api = PublicApi

  const [submitDataLoading, setSubmitDataLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [rePasswordErr, setRePasswordErr] = useState('');
  const [alert, setAlert] = useState({ open: false, type: 'success', msg: '' });

  const [isSuccess, setIsSuccess] = useState(false);

  const reset = () => {
    setSubmitDataLoading(false)
    setPassword('')
    setRePassword('')
    setPasswordErr('')
    setRePasswordErr('')
  };
  
  const showErrMsg = message => {
    if(message.includes("تأكيد كلمة المرور")){
      setRePasswordErr(message)
    } else if(message.includes("كلمة المرور")){
      setPasswordErr(message)
    }
  }

  const EditDataRequest = async () => {
    try {
      setSubmitDataLoading(true)

      const response = await api.patch('/auth/email/reset-password', { _id, token, password, re_password: rePassword })
      console.log(response, 'patch data');

      setAlert({ open: true, type: 'success', msg: 'تم تعديل كلمة المرور بنجاح' })
      setTimeout(() => setAlert({ open: false, type: 'success', msg: 'تم تعديل كلمة المرور بنجاح' }), 4000);
      reset()

      setIsSuccess(true)
      
    } catch (error) {
      console.log(error.response.data.error, 'patch error');
      setSubmitDataLoading(false)

      if(error?.response){
        const { status, message } = error.response.data.error
        if(status === 422){
          showErrMsg(message)
        } else if(status === 401) {
          setAlert({ open: true, type: 'error', msg: message })
          setTimeout(() => setAlert({ open: false, type: 'error', msg: message }), 4000);
        } else if(status === 404) {
          navigate('/NotFound')
        } else if(status >= 500){
          navigate('/serverError')
        }
      }
    }
  }

  const handleSubmit = async e => {
      e.preventDefault();

      try {
          await ResetPasswordSchema.validateAsync({ password, re_password: rePassword })
          EditDataRequest()
      } catch ({ message }) {
          showErrMsg(message)
      }
  }

  useEffect(() => {
    if(!_id || !token) navigate('/NotFoundPage')
    return () => reset()
  }, [_id, token, navigate]);



  return (
    <>
      <Stack spacing={1} justifyContent={'flex-start'} alignItems={'center'} sx={{ pt: 10, background: 'rgba(0, 0, 0, 0.05)', height: '100vh'}}>
        <Box component="div" sx={{ width: '200px', height: '200px'}}>
            <img src='/nkaty.png' alt="Nkaty" title="Nkaty" loading='lazy' style={{ objectFit: 'contain', width: '100%', height: '100%'}}/>
        </Box>
        
        <div>{!isSuccess ? title : <Typography variant="h5" component="div" sx={{ mb: 2.5 }}> { "تم تعديل كلمة المرور بنجاح, برجاء إغلاق الصفحة" } </Typography>}</div>
        
        { !isSuccess && <Box
            onSubmit={handleSubmit}
            component="form"
            noValidate
            style={{ margin: '20px 15px 50px', maxWidth: '300px' }}
            className='boxForm'
        >
            <Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>
                <TextField
                    fullWidth
                    error={passwordErr.length > 0 && true }
                    id="outlined-error-password"
                    label="كلمة المرور"
                    helperText={passwordErr}
                    value={password}
                    type="password"
                    onChange={e => {
                      setPassword(e.target.value)
                      setPasswordErr('')
                      setRePasswordErr('')
                    }}
                />
                
                <TextField
                    fullWidth
                    error={rePasswordErr.length > 0 && true }
                    id="outlined-error-re-password"
                    label="تأكيد كلمة المرور"
                    helperText={rePasswordErr}
                    value={rePassword}
                    type="password"
                    onChange={e => {
                      setRePassword(e.target.value)
                      setPasswordErr('')
                      setRePasswordErr('')
                    }}
                />

                <LoadingButton
                    fullWidth
                    className='loadingBtn'
                    startIcon={<EditIcon className='btnLogo' />}
                    loading={submitDataLoading}
                    loadingPosition="start"
                    variant="contained"
                    color="secondary"
                    type="submit"
                >
                    <span>تعديل كلمة المرور</span>
                </LoadingButton>

            </Stack>
        </Box>}
      </Stack>

      <AppAlert open={alert.open} type={alert.type} msg={alert.msg} />
    </>
  )
}